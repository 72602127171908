<template>
  <div class="mt-1">
    <!-- Table Container Card -->

    <b-card>
      <b-overlay :show="loading" rounded="sm" no-fade>
        <b-row class="mb-1">
          <!-- Per Page -->
          <b-col cols="12" md="7" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>entries </label>
          </b-col>

          <b-col cols="12" md="5">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" cle placeholder="Search..." />
              <b-button variant="primary" router-link to="/user/user-add">
                <span class="text-nowrap">Add User</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-table hover ref="refUserListTable" class="position-relative" :items="fetchUsers" :outlined="true" selectable select-mode="single" responsive :small="true" :fields="tableColumns" show-empty empty-text="No matching records found" @row-selected="onRowSelected" :per-page="perPage">
          <!-- Column: Id -->
          <template #cell(id)="data">
            <!--   <b-link :to="{ name: 'customer-edit', params: { id: data.item.id } }" class="font-weight-bold"> #{{ data.value }} </b-link>-->
            <strong class="text-primary">#{{ data.value }}</strong>
          </template>

          <!-- Column: Name -->
          <template #cell(name)="data">
            {{ data.value }}
          </template>

          <!-- Column: Name -->
          <template #cell(surname)="data">
            {{ data.value }}
          </template>

          <!-- Column: Role -->
          <template #cell(role)="data">
            <b-badge pill :variant="`light-primary`" class="text-capitalize">
              {{ data.value }}
            </b-badge>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge v-if="data.value == 'active'" pill :variant="`light-success`" class="text-capitalize">
              Active
            </b-badge>

            <b-badge v-if="data.value == 'passive'" pill :variant="`light-danger`" class="text-capitalize">
              Passive
            </b-badge>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>

            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import userStoreModule from '../userStoreModule';
import vSelect from 'vue-select';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    ToastificationContent,
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'user';
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      fetchUsers: [],
      tableColumns: [
        { key: 'id', sortable: true, class: 'text-center' },
        { key: 'name', sortable: true, class: 'text-center' },
        { key: 'surname', sortable: true, class: 'text-center' },
        { key: 'email', sortable: true, class: 'text-center' },
        { key: 'role', sortable: true, class: 'text-center' },
        { key: 'status', sortable: true, class: 'text-center' },
      ],
      perPage: parseInt(localStorage.getItem('formShow')) || 10,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [10, 25, 50, 100],
      loading: true,
      searchQuery: '',
    };
  },

  watch: {
    perPage: 'debouncedRefetchFilter',
    currentPage: 'debouncedRefetchFilter',
    searchQuery: 'debouncedFilter',
  },

  methods: {
    debouncedRefetchFilter: function() {
      this.loading = true;
      clearTimeout(this.timer);
      localStorage.setItem('userShow', this.perPage);
      this.timer = setTimeout(() => {
        this.getUsers();
      }, 200);
    },

    debouncedFilter: function() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.getUsers();
      }, 1000);
    },

    onRowSelected(item) {
      router.push({ name: 'user-edit', params: { id: item[0].id } });
    },

    getUsers() {
      this.loading = true;
      store
        .dispatch('user/getAllUsers', {
          perPage: this.perPage,
          currentPage: this.currentPage,
          searchQuery: this.searchQuery,
        })
        .then((res) => {
          this.totalRows = res.data.total;
          this.fetchUsers = res.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          });
        });
    },
  },

  created() {
    this.getUsers();
  },

  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
